export const AppConstants = {
  ACCEPTED_DIALOG_RESPONSE: 'Accepted',
  AFFIRMATIVE_VALUES: [
    'si',
    'sí',
    'yes'
  ],
  AMPERSAND: '&',
  ASCENDENT: 'asc',
  BREAK_LINE: '\n',
  CANCEL: 'Cancel',
  CARRIAGE_RETURN: '\r',
  CLOSED: 'closed',
  COLON: ': ',
  COMMA_SPACE: ', ',
  CONFIRM: 'Confirm',
  CUBIC_METERS: 'm³',
  CURRENCY_KEYWORD: 'currency',
  CURRENCY_SIGN: '$ ',
  CURRENCY_SIGN_NO_SPACE: '$',
  CUSTOMER_PICKUP_INITIALS: 'CR',
  DASH: '-',
  DATE_FORMAT: 'DD/MM/YYYY',
  DATE_SEPARATOR: 'T',
  DEF_FIXED_DECIMALS: 3,
  DEFAULT_COLLAPSED_HEIGHT: '35px',
  DEFAULT_EXPANDED_HEIGHT: '35px',
  DELETE: 'Delete',
  DIALOG_STANDARD_SIZE: '650px',
  DIALOG_STANDARD_HEIGHT: '300px',
  DOT_CHAR: '.',
  EMPTY_STRING: '',
  END_DAY_HOUR: {
    hours: 23,
    minutes: 59,
    seconds: 59
  },
  EVIDENCE_MAIN_VERSION: '3.0.0',
  GOOGLE_SUCCESS_RESPONSE: 'OK',
  GRID_CONFIGS: 'gridConfigs',
  HYPHEN_WITH_SPACES: ' - ',
  IMAGES_FILES_FORMAT_ALLOWED: ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'],
  INIT_PARENTHESIS: '(',
  INTERWAREHOUSE_POSITION: 6,
  INVALID_INDEX: -1,
  IS_CONSULTING_SHIPMENT_KEY: 'IsConsultingShipmentDetails',
  ISR_FACTOR: 0.0125,
  IVA_FACTOR: 0.16,
  KEY_BLOBNAME: 'blobName',
  KEY_EN: 'en',
  KEY_ES: 'es',
  KEY_NUMBER: 'number',
  KEY_SASURL: 'sasUrl',
  KEY_STRING: 'string',
  KG: 'Kg',
  LOW_DASH: '_',
  MAX_DEFAULT_COST: 9999999.99,
  MAX_LENGTH_DOCS: 300,
  MAX_LENGTH_INPUT_VALUE: 50,
  MAX_LENGTH_MUNICIPALITY: 100,
  MAX_QUANTITY_MERCHANDISE: 9999999,
  MAX_SIZE_FILE_EVIDENCE: 25000000,
  MAX_TIME: { hour: 23, minutes: 59, miliseconds: 999, minsAndSecs: 59 },
  MEXICO_COUNTRY: 'México',
  MEXICO_KEY: 'MX',
  MIN_DISTANCE_TRAVELED: '0.01',
  MIN_FACTOR: 0.01,
  MIN_FIXED_DECIMALS: 2,
  MIN_LENGTH_INPUT_VALUE: 3,
  MIN_LENGTH_ORDER_SEARCH_CRITERIA: 3,
  MIN_VALUE_DECIMAL_INPUT: 0.0001,
  NODE_ENVIROMENTS: ['staging', 'release', 'develop'],
  NORMALIZATION: 'NFD',
  NO_APPLY: 'N/A',
  NUMBER_DIGITS: '1.0',
  NUMBER_SYMBOL: '#',
  OBJECT: 'object',
  OBJECT_ID_KEY: '_id',
  PAGE_SIZE_OPTIONS: [25, 50, 100],
  PANEL_CLASS: 'dialog-success-detail',
  ORDER_BULK_LOAD_MODULE_NAME: 'orders-bulk-load',
  PDF_FILE: 'application/pdf',
  PDF_PREFIX: 'pdf',
  PERCENTAGE_VALUE: 100,
  PRODUCTIVE_ENVIROMENT_VALUE: 'production',
  QR_DEFAULT_CORRECTION_LEVEL: 'M',
  QR_DEFAULT_ELEMENT_TYPE: 'svg',
  RADIX: 10,
  RETENTION_FACTOR: 0.04,
  SCF_DEFAULT_GRID_PAGE_SIZE: 25,
  SHIPMENT_INITIALS: 'EM',
  SHIPPER_DEFAULT_LOGO: 'assets/icons/solutions/tepLogo2.jpg',
  SLASH: '/',
  SOLUTION_NAME: 'TEP',
  SPANISH_KEY: 'es',
  SYSADMIN: 'Sysadmin',
  SYSTEM_DEFAULT_LOGO: 'assets/icons/solutions/tep-logo.svg',
  TIME_OUT_RESTART_SCF_GRID: 100,
  UNITED_STATES_DOLLAR: 'USD',
  UNDERSCORE: '_',
  US_KEY: 'US',
  US_NUMBER_FORMAT: 'en-US',
  USER_INFO_KEY: 'userInfo',
  WHITE_SPACE: ' ',
  ZERO_STRING: '0',
  ZERO: 0
};
