import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { OrderDataGetAuthorization } from '../../interfaces/remission-guides';

const API_URL = environment.apiUrl;


@Injectable()

/**
 * Service for remission guides module with call to endpoints relatred with remission guides.
 */
export class RemissionGuidesProviderService {
  /**
   * @description Remission guides provider service.
   * @param { HttpClient } http - Service instance for http request.
   */
  constructor(
    private http: HttpClient
  ) { }

  /**
   * @description Send orders data with remission guides info to be authorized.
   * @param {string} tenantId - OId from tenant.
   * @param {Array<OrderDataGetAuthorization>} data - All data from orders, included remission guides for each one to be authorized.
   */
  public async getAutorizationForRemissionGuides(tenantId: string, data: Array<OrderDataGetAuthorization>): Promise<void> {
    return await this.http.post<void>(`${API_URL}/tenant/${tenantId}/remissionGuides`, data).toPromise();
  }
}
