export const INVOICE_PROPORSAL = {
  ACCEPTED: 'Accepted',
  ADDITIONAL_CHARGE: 'additionalCharge',
  APPROVED_CLASS: 'approved-chip',
  BY_ORDER: 'byOrder',
  BY_SHIPMENT: 'byShipment',
  CHARGES: 'additionalCharges',
  CHARGES_SAVED: 'additionalChargesSaved',
  CHARGES_TO_SAVE: 'additionalChargesToSave',
  CLOSED: 'Closed',
  COLLAPSED_HEIGHT: '35px',
  CONCEPT: 'concept',
  CONFIRM: 'Confirm',
  CONTROL_ACCOUNT_NAME: 'account',
  CONTROL_ORDER_NAME: 'orderId',
  CONTROL_SEARCH_TYPE_NAME: 'searchType',
  CONTROL_SHIPMENT_NAME: 'shipmentId',
  COPY_COMMAND: 'copy',
  COSTOMER_CHARGE_FREIGHT: 'costumerChargeFreight',
  COSTUMER_CHARGE_FREIGHT_VALUE: 'costumerChargeFreightValue',
  DAYS_ON_MONTH: 30,
  DEFAULT_VAT: 0.16,
  DATABASE_PROFIT_DIGITS: 4,
  DIALOG_WIDTH: '650px',
  DISCOUNT: 'discount',
  DISCOUNTS: 'discounts',
  DISCOUNTS_SAVED: 'discountsSaved',
  DISCOUNTS_TO_SAVE: 'discountsToSave',
  DISPLAY_COLUMNS: ['label', 'amount'],
  DISPLAY_ORDERS_COLUMNS: [
    'isSelected',
    'folio',
    'identifier',
    'invoice',
    'shipmentId',
    'inRouteDate',
    'unitType',
    'state',
    'municipality',
    'pieces',
    'pallets',
    'freight',
    'costumerChargeFreight',
    'totalCharges',
    'subtotal',
    'actions'
  ],
  END_HOUR: 23,
  END_MIN_AND_SEC: 59,
  END_MS: 999,
  EXPANDED_HEIGHT: '35px',
  FILTER_FOLIO: 'folio',
  FILTER_IDENTIFIER: 'identifier',
  FILTER_INVOICE: 'invoice',
  FIXED_TOTALS: 3,
  GENERAL_SEARCH_ICON: '../../../assets/icons/general-search.svg',
  GENERATES_STATUS: 'Generada',
  ICON_ARROW_DOWN: '../../../../assets/icons/Icon-arrowdown.svg',
  ICON_ARROW_UP: '../../../../assets/icons/Icon-arrowup.svg',
  INFO_ICON_IMG: '../../../../assets/icons/info.svg',
  KEY_EN: 'en',
  KEY_ES: 'es',
  KEY_ICON_INFO: 'info',
  KEY_IDENTIFIER: 'identifier',
  KEY_VIEWMODE: 'viewMode',
  LOCAL_CHARGES_TO_SAVE: 'localChargesToSave',
  LOCAL_DISCOUNTS_TO_SAVE: 'localDiscountsToSave',
  MIN_LENGTH_INVOICE_CONCEPT: 120,
  MODAL_IMAGE: '../../../../assets/confirmConfigModal.svg',
  MODAL_SIZE: '650px',
  MONEY_SIGN: "$",
  ON_APROBATION: 'on-aprobation-chip',
  ORDERS: 'orders',
  PAGE_SIZE: 25,
  PAGE_SIZE_OPTIONS: [25, 50, 100],
  PANEL_CLASS: 'dialog-success-detail',
  PENALIZATIONS: 'Penalizaciones',
  PERCENTAGE: ' %',
  ROUTE: 'cost/invoice-proposal',
  SAVED_STATUS: 'Guardada',
  SEARCH: 'search',
  SPECIFIC_SEARCH_ICON: '../../../assets/icons/specific-search.svg',
  SUBTOTAL: 'subTotal',
  SUBTOTAL_VALUE: 'subTotalValue',
  TARGET: '_blank',
  TIME_OUT: 109,
  TOTAL_CHARGES_VALUE: 'totalChargesValue',
  TOTAL_TABLE_PROFIT_DIGITS: 2,
  VIEWMODE_REPORT: 'report',
}
