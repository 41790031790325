import { ShipmentStatus } from '../../../enums/shipment-status';

export const REMISSION_GUIDES_PROPERTIES = {
  checkDigitExample: 9,
  confirmation: 'SI',
  inputSearchMinLength: 3,
  limitHours: 24,
  limitMiliseconds: 1000,
  limitMinutes: 60,
  maxDaysMonth: 31,
  maxDaysRange: 30,
  maxRowsAvailableInFirstPage: 6,
  maxRowsAvailableInPage: 17,
  minRecordsSelected: 2,
  negation: 'NO',
  numericCodeExample: '00000002',
  orderEmitterPointExample: '100',
  orderEstablismentExample: '1000',
  printOrderGuidesButtonId: 'orc-print-button',
  printShipmentGuidesButtonId: 'src-print-button',
  productSerieExample: '000001',
  productiveEnviromentValue: 2,
  qrCodeWidth: 55,
  remissionGuideSequencialExample: '000000001',
  rowsQuantityRequiredForFirstProductInPage: 2,
  searchHeaderSize: '40px',
  sortShipmentsBy: 'confirmationByInterface',
  sriStatusAuthorizated: 'sri-status-authorizated',
  sriStatusInValidation: 'sri-status-in-validation',
  sriStatusNoGenerated: 'sri-status-no-generated',
  sriStatusRejected: 'sri-status-rejected',
  sriStatusReturned: 'sri-status-returned'
};

export const SHIPMENT_STATUS_ALLOWED = [
  ShipmentStatus.confirmed.toString()
];

export const SHIPMENT_GRID_COLUMNS = [
  'isShipmentSelected',
  'shipmentId',
  'internalReference',
  'tripType',
  'serviceType',
  'origin',
  'destination',
  'actions'
];

export const ORDER_GRID_COLUMNS = [
  'isOrderSelected',
  'sriStatus',
  'orderId',
  'corporation',
  'corporationRuc',
  'remissionGuideConsecutive',
  'authorizationNumber',
  'grouperClass',
  'grouperNumber',
  'grouperCreationDate',
  'deliveryClass',
  'deliveryNumber',
  'orderCreationDate',
  'docNumber',
  'transferReason',
  'receiver',
  'receiverRuc',
  'totalPieces',
  'totalVolume',
  'carrier',
  'driver',
  'vehicleType',
  'plates',
  'remissionGuideCreatedBy',
  'dateSentToAuthorization',
  'authorizationMessage',
  'invoiceNumber',
  'actions'
];

export const SPECIFIC_SEARCH_PARAMS = [
  'shipmentFolio',
  'orderIdentifier'
];
